import { Component } from '@angular/core';
import { GlobalService } from './services/global.service';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { environment } from './../environments/environment';
declare var jQuery: any;


//const segurl = window.location.pathname.split("/");
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(private global: GlobalService, private router: Router,  private location: Location) { }
    ngOnInit() {
        let utm_source = this.getParameterByName('utm_source');
        
        if(utm_source != "" && utm_source != null) {
            let utm_medium = this.getParameterByName('utm_medium');
            if(utm_source.indexOf("oyoworkspaces") > -1){
                sessionStorage.setItem("referalName", "oyo");
                sessionStorage.setItem("referal", "true");
                sessionStorage.setItem("referalMedium", utm_medium);
                this.global.webapi('logreferral', {'referral':'oyo', 'medium': utm_medium}).subscribe((res: any) => {
                });
            }
            else if(utm_source.indexOf("avance") > -1 || utm_source.indexOf("avanse") > -1){
                sessionStorage.setItem("referalName", "avance");
                sessionStorage.setItem("referal", "true");
                sessionStorage.setItem("referalMedium", utm_medium);
                sessionStorage.setItem("isAvanseUser", 'true');
                this.global.webapi('logreferral', {'referral':'avance', 'medium': utm_medium}).subscribe((res: any) => {
                });
            }
        }
        /*window.onunload = () => {            
            sessionStorage.removeItem("referalName");
            sessionStorage.removeItem("referal");
            sessionStorage.removeItem("referalMedium");
            sessionStorage.removeItem("isAvanseUser");
        }*/
        
        this.segment();
        setTimeout(() => { jQuery('#loader_image').hide(); }, 300);
        
    } 

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    is_loggedin: any = false;
    segment() {
      this.router.events.subscribe((val) => {
        let segments = this.location.path().split("/");
        if (segments[1] != undefined && segments[1] == 'account') {
          this.is_loggedin = true; 
        }else{
            this.is_loggedin = false; 
        }
      });
    }
}

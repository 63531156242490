import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap'; 
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule, ReactiveFormsModule}   from '@angular/forms'; 
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 


import { AppComponent } from './app.component';



/* public theme */
import { NgxPopper } from 'angular-popper';
import { CommunityComponent } from './community/community.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';











 
@NgModule({
  declarations: [
    AppComponent,
    CommunityComponent,
  
   
      
    
  ],

  imports: [
    BrowserModule,
    NgbModule,
    AngularFontAwesomeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxPopper,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

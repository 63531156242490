import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  
  /*{ path: 'profile/:id', loadChildren: './pages/mentor/mentor.module#MentorModule'},
   { path: 'mentor', loadChildren: './pages/mentor/mentor.module#MentorModule' },
   { path: 'test', loadChildren: './test/test.module#TestModule' },
   { path: 'social-login', loadChildren: './home/social-login/social-login.module#SocialLoginModule' },*/

   /* admin routing */   
   { path: 'account', loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard] },
   { path: 'community', loadChildren: './community/community.module#CommunityModule'},
   { path: 'apb-tc', loadChildren: './apb-tc/apb-tc.module#ApbTcModule'},
   { path: 'linkedin-cancel', loadChildren: './linkedin-redirect/linkedin-redirect.module#LinkedinRedirectModule'},
   { path: 'talent-finder', loadChildren: './talent-finder/talent-finder.module#TalentFinderModule'},
   
   { path: 'career-card', loadChildren: './career-card/career-card.module#CareerCardModule'},
   /* home routing (it must be bottom of all routing) */
   { path: '', loadChildren: './home/home.module#HomeModule' },
];
@NgModule({
   imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
   exports: [RouterModule]
})
export class AppRoutingModule { }